import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useMutation } from "@vue/apollo-composable";
import { gql } from "@apollo/client/core";
import { MutationRemoveExpenseArgs } from "@/graphql/types";
import { useConfirm } from "primevue/useconfirm";
import { removeActiveSequenceExpense } from "@/graphql/sequence/sequence.expense.cache";

type RemoveExpenseData = {
  removeExpense: number;
};
const REMOVE_EXPENSE = gql`
  mutation RemoveExpense($id: String!) {
    removeExpense(id: $id)
  }
`;
export const useRemoveExpense = () => {
  const toast = useToast();
  const { t } = useI18n();
  const confirm = useConfirm();
  const {
    loading: loadingRemove,
    mutate,
    onDone,
  } = useMutation<RemoveExpenseData, MutationRemoveExpenseArgs>(REMOVE_EXPENSE);
  onDone(({ data }) => {
    const success = data?.removeExpense > 0;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("delete.title"),
      detail: t(`delete.${success ? "success" : "failed"}`),
      life: 3000,
    });
  });
  function submitRemove(event: any, id: string) {
    confirm.require({
      target: event.currentTarget,
      message: t("expense.removeWarning"),
      icon: "pi pi-exclamation-triangle",
      rejectLabel: t("no"),
      acceptLabel: t("yes"),
      acceptClass: "p-button-warning",
      accept: () => {
        void mutate(
          { id },
          {
            update(cache, { data }) {
              if (data?.removeExpense) {
                cache.modify({
                  fields: {
                    paginateExpenses(existing: any, { readField }) {
                      return existing.filter(
                        (eRef: any) => id != readField("id", eRef)
                      );
                    },
                  },
                });
                removeActiveSequenceExpense(cache, id);
              }
            },
          }
        );
      },
    });
  }
  return {
    submitRemove,
    loadingRemove,
  };
};
