import {
  MutationCreateExpenseArgs,
  Expense,
  CreateExpenseInput,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { EXPENSE_FIELDS } from "@/graphql/expense/expense";
import { addActiveSequenceExpense } from "@/graphql/sequence/sequence.expense.cache";

type CreateExpenseData = {
  createExpense: Expense;
};

const CREATE_EXPENSE = gql`
    mutation CreateExpense($input: CreateExpenseInput!){
        createExpense(input: $input) {
            ${EXPENSE_FIELDS}
        }
    }
`;
export const useCreateExpense = (callback: () => void) => {
  const toast = useToast();
  const { t } = useI18n();
  const {
    loading: loadCreation,
    mutate,
    onDone,
  } = useMutation<CreateExpenseData, MutationCreateExpenseArgs>(
    CREATE_EXPENSE,
    {
      context: { hasUpload: true },
      update(cache, { data }) {
        if (data?.createExpense) {
          cache.modify({
            fields: {
              paginateExpenses(existingRef: any, { toReference }) {
                return [toReference(data.createExpense), ...existingRef];
              },
            },
          });
          if (data.createExpense?.sequenceId)
            addActiveSequenceExpense(cache, data.createExpense);
        }
      },
    }
  );
  onDone(({ data }) => {
    const success = !!data?.createExpense;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("create.title"),
      detail: t(`create.${success ? "success" : "failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });

    if (success) callback();
  });
  function submitCreation(input: CreateExpenseInput) {
    void mutate({ input });
  }
  return { submitCreation, loadCreation };
};
