import { Category, QueryExpenseCategoriesArgs } from "src/graphql/types";
import { gql } from "@apollo/client";
import { useQuery, useResult } from "@vue/apollo-composable";
import { CATEGORY_FIELDS } from "@/graphql/category/category";
import { activeActivity } from "@/plugins/i18n";

type FindAllCategoriesData = {
  expenseCategories: Category[];
};

const EXPENSE_CATEGORIES = gql`
  query ExpenseCategories($input: Int!) {
      expenseCategories(input: $input) {
      ${CATEGORY_FIELDS}
    }
  }
`;
export const useExpenseCategories = () => {
  const { loading, result } = useQuery<
    FindAllCategoriesData,
    QueryExpenseCategoriesArgs
  >(EXPENSE_CATEGORIES, { input: activeActivity.value.id });
  const categories = useResult<FindAllCategoriesData, Category[], Category[]>(
    result,
    [],
    (res) => res.expenseCategories
  );
  return { loading, categories };
};
