
import { defineComponent, reactive, ref } from "vue";
import { formatNumber, numberSeparators } from "@/graphql/utils/utils";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { useRemoveExpense } from "@/graphql/expense/remove.expense";
import { activeActivity, subscriptionExpired } from "@/plugins/i18n";
import ExpenseForm from "@/components/expense/ExpenseForm.vue";
import { useExpenseCategories } from "@/graphql/category/expense.categories";
import { Expense } from "@/graphql/types";
import CategoryMultiSelect from "@/components/category/CategoryMultiSelect.vue";

const FILTER = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  "category.id": {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
  motif: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  amount: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};

export default defineComponent({
  name: "ExpenseTable",
  components: { ExpenseForm, CategoryMultiSelect },
  props: ["loading", "items", "sequenceId"],
  emits: ["search"],
  setup() {
    const dialog = reactive({
      expense: null,
      visible: false,
    });
    function openDialog(expense: Expense) {
      dialog.expense = expense;
      dialog.visible = true;
    }
    const filteredTotal = ref(0);

    function onFilter(event: any) {
      filteredTotal.value = (event.filteredValue as Expense[]).reduce(
        (acc, cum) => acc + cum.amount,
        0
      );
    }
    return {
      filters: ref(FILTER),
      ...useRemoveExpense(),
      numberSeparators,
      activeActivity,
      expired: subscriptionExpired(),
      ...useExpenseCategories(),
      openDialog,
      dialog,
      onFilter,
      filteredTotal,
      formatNumber,
    };
  },
  methods: {
    clearFilter() {
      this.filters = FILTER;
    },
  },
});
