import {
  Expense,
  MutationUpdateExpenseArgs,
  UpdateExpenseInput,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { EXPENSE_FIELDS } from "@/graphql/expense/expense";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

type UpdateExpenseData = {
  updateExpense: Expense;
};

const UPDATE_EXPENSE = gql`
    mutation UpdateExpense($input: UpdateExpenseInput!) {
        updateExpense(input: $input) {
            ${EXPENSE_FIELDS}
        }
    }
`;

export const useUpdateExpense = (callback: () => void) => {
  const toast = useToast();
  const { t } = useI18n();
  const {
    loading: updateLoading,
    mutate,
    onDone,
  } = useMutation<UpdateExpenseData, MutationUpdateExpenseArgs>(UPDATE_EXPENSE);

  onDone(({ data }) => {
    const success = !!data?.updateExpense;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("update.title"),
      detail: t(`update.${success ? "success" : "failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    if (success) callback();
  });
  function onRowEditSave(input: UpdateExpenseInput) {
    void mutate({ input });
  }
  return { updateLoading, onRowEditSave };
};
