import { ApolloCache } from "@apollo/client/core";
import { Expense, QueryActiveSequenceArgs } from "@/graphql/types";
import { cloneDeep } from "@apollo/client/utilities";
import {
  ACTIVE_SEQUENCE,
  ActiveSequenceData,
  getActiveTerminal,
} from "@/graphql/sequence/active.sequence";

export const addActiveSequenceExpense = (
  cache: ApolloCache<any>,
  data: Expense
) => {
  const terminalId = getActiveTerminal().id;
  const active = cache.readQuery<ActiveSequenceData, QueryActiveSequenceArgs>({
    query: ACTIVE_SEQUENCE,
    variables: { terminalId },
  });
  if (active.activeSequence) {
    const { sequence, ...res } = cloneDeep(active.activeSequence);
    sequence.expenses.unshift(data);
    cache.writeQuery<ActiveSequenceData, QueryActiveSequenceArgs>({
      data: { activeSequence: { ...res, sequence } },
      query: ACTIVE_SEQUENCE,
      variables: { terminalId },
    });
  }
};

export const removeActiveSequenceExpense = (
  cache: ApolloCache<any>,
  id: string
) => {
  const terminalId = getActiveTerminal().id;
  const active = cache.readQuery<ActiveSequenceData, QueryActiveSequenceArgs>({
    query: ACTIVE_SEQUENCE,
    variables: { terminalId },
  });
  if (active?.activeSequence) {
    const { sequence, ...res } = cloneDeep(active.activeSequence);
    sequence.expenses = sequence.expenses.filter((e) => e.id !== id);
    cache.writeQuery<ActiveSequenceData, QueryActiveSequenceArgs>({
      data: { activeSequence: { ...res, sequence } },
      query: ACTIVE_SEQUENCE,
      variables: { terminalId },
    });
  }
};

export const deleteActiveSequenceMovement = (
  cache: ApolloCache<any>,
  id: string
) => {
  const terminalId = getActiveTerminal().id;
  const active = cache.readQuery<ActiveSequenceData, QueryActiveSequenceArgs>({
    query: ACTIVE_SEQUENCE,
    variables: { terminalId },
  });
  if (active.activeSequence) {
    const tickets = cloneDeep(active.activeSequence.tickets);
    for (let i = 0; i < tickets.length; i++) {
      const index = tickets[i].movements.findIndex((m) => m.id === id);
      if (index > -1) {
        tickets[i].movements.splice(index, 1);
        break;
      }
    }
    cache.writeQuery<ActiveSequenceData, QueryActiveSequenceArgs>({
      data: { activeSequence: { ...active.activeSequence, tickets } },
      query: ACTIVE_SEQUENCE,
      variables: { terminalId },
    });
  }
};
